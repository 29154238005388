import React from "react";
import date from "date-and-time";
import ClickToCopy from "../../../../../modules/clickToCopy";
import {
  always,
  join,
  juxt,
  map,
  pathOr,
  pipe,
  propOr,
  uniq,
  uniqBy,
} from "ramda";

export const paymentCellModifier = (data, header) => {
  switch (header) {
    case "createdAt":
      data = date.format(new Date(data[header]), "DD MM YYYY hh:mmA");
      break;
    default:
      data = data[header];
  }
  return <ClickToCopy data={String(data)}>{data}</ClickToCopy>;
};

export const getBatchSchedule = pipe(
  map(
    pipe(
      juxt([
        pipe(propOr(0, "startTime"), (dt) => date.format(new Date(dt), "ddd")),
        always(": "),
        pipe(propOr(0, "startTime"), (t) => date.format(new Date(t), "h:mm A")),
        always(" - "),
        pipe(propOr(0, "endTime"), (t) => date.format(new Date(t), "h:mm A")),
      ]),
      join("")
    )
  ),
  uniq
);

export const getScheduleStart = pipe(
  uniqBy(
    pipe(propOr(0, "startTime"), (dt) =>
      date.format(new Date(dt), "ddd h:mm A")
    )
  ),
  map(pathOr(0, ["startTime"]))
);

export const getSchedule = (startTime, duration) => {
  let schedule = date.format(new Date(startTime), "ddd: h:mm A");
  return schedule.concat(
    date.format(new Date(startTime + duration * 60 * 60 * 1000), " - h:mm A")
  );
};

export const getNewSlots = (
  schedule = [],
  duration,
  startSlot,
  startDate,
  nClasses
) => {
  schedule.sort((a, b) => new Date(a).getDay() - new Date(b).getDay());
  const startingIndex = schedule.indexOf(startSlot);
  const startDay = new Date(startSlot).getDay();

  const slots = [];
  [...Array(nClasses)].forEach((_, i) => {
    const week = Math.floor((i + startingIndex) / schedule.length);
    const si = (i + startingIndex) % schedule.length;
    const dt = new Date(schedule[si]);

    const off1 = (week * 7 + (dt.getDay() - startDay)) * 24 * 60 * 60 * 1000;
    const off2 = dt.getHours() * 60 * 60 * 1000 + dt.getMinutes() * 60 * 1000;
    const startTime = startDate + off1 + off2;

    slots.push({ startTime, endTime: startTime + duration * 60 * 60 * 1000 });
  });

  return slots;
};

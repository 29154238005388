import styled from "styled-components";
import {
	background,
	hFlex,
	modalBox,
	square,
	vFlex,
} from "../../../../../styles/style-helpers";
import { ActiveSecEl, FilterNavEl } from "../../../elements";

export const ClassInfoEl = styled.div`
  ${hFlex};
  align-items: unset;
  padding: 1em 0;
`;

export const ProfileInfoEl = styled.div`
  ${vFlex};
  flex: 1 1;
  align-items: flex-start;
  justify-content: space-around;

  p {
    color: #4a4a4a;
    margin: 0.3em 0;
  }

  button {
    margin-bottom: 0.5em;
  }
`;

export const ProfilePicEl = styled.div`
  ${() => square("14vw")};
  ${(props) => background(props.image, "cover")};
  margin-right: 2em;
  border-radius: 10px;
`;

export const StatusBarEl = styled.div`
  ${hFlex};
  position: absolute;
  top: 1em;
  margin: 0 auto;
  width: 95%;
  background-color: white;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  padding-left: 0.5em;
  z-index: 2;

  div {
    flex: 1 1;
  }

  button {
    margin-right: 0.6em;
  }
`;

export const VideoSnippetsEl = styled.div`
  display: flex;
  flex-flow: wrap;

  div {
    margin: 0.2em 0.2em 0 0;
  }
`;

export const WhitelistEmailEl = styled.div`
  ol {
    padding: 0.1em 0 0 1em;
  }

  li:hover {
    button {
      opacity: 1;
    }
  }

  button {
    opacity: 0;
  }
`;

export const CoHostEl = styled.div`
  ol {
    padding: 0.1em 0 0 1em;
  }

  button {
    margin-left: 1em;
  }

  li:hover {
    button {
      opacity: 1;
    }
  }

  button {
    opacity: 0;
  }
`;

export const DiscountsEl = styled.div`
  ${hFlex};
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;

export const DiscountEl = styled.div`
  ${hFlex};
  width: 30%;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25);
  padding: 1em 0;
  margin: 1em 0 0 1em;
  justify-content: space-around;
`;

export const PaymentInfoModalEl = styled.div`
  ${modalBox};
  ${vFlex};
  width: 95%;
  max-height: 85vh;
  align-items: flex-start;

  > * {
    margin: 0.5em 0;
  }
`;

export const ClassFilterNavEl = styled(FilterNavEl)`
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1em;
  z-index: 2;
`;

export const ActiveClassSecEl = styled(ActiveSecEl)`
  padding: 0;
`;

export const PaymentBasicInfoEl = styled.div`
  ${hFlex};
  width: 100%;
  justify-content: space-between;
`;

export const RescheduleClassFormEl = styled.form`
  width: 100%;
`;

export const DateTimeEl = styled.div`
  ${hFlex};
  justify-content: space-around;
  margin: 1em 0;

  > div {
    margin: 0 0.5em;
    max-width: 40%;
  }
`;

export const TimeEl = styled.div`
  ${hFlex};
  align-items: flex-end;
  width: 50%;

  > div {
    width: auto;
    margin: 0 0.5em;
  }
`;

export const VisualSettingsEl = styled.div`
  ${vFlex};

  .visual-setting-sec {
    position: relative;
    border-top: 1px solid black;
    padding: 1em 0.5em;
    margin-bottom: 1.5em;
  }

  .cover-image-upload {
    ${vFlex};
    align-items: baseline;
    width: 100%;
    border: 0;

    h5 {
      width: 100%;

      button {
        float: right;
      }
    }
  }

  .lobby-assets {
    width: 100%;

    h5 {
      width: 100%;

      button {
        float: right;
        margin-left: 1em;
      }
    }

    > div {
      width: 100%;
    }
  }

  .cover-video {
    width: 100%;
  }
`;

export const ClassSettingsEl = styled.div`
  ${vFlex};

  > div:nth-child(n + 2) {
    border-top: 1px solid black;
  }

  .class-setting-sec {
    position: relative;
    padding: 0.75em 0.5em;
    width: 100%;
  }

  .class-registration-limit {
    margin-bottom: 1.5em;
  }
`;

export const LiveStreamingEl = styled.form`
  width: 100%;
  margin-bottom: 0.5em;

  .url-key {
    ${hFlex}
    width: 100%;
    margin-bottom: 1em;
    > div {
      width: 45%;
      margin: 0 2.5% 0 2.5%;
    }
  }
  button {
    width: 20%;
  }
`;

export const ResourcesEl = styled.div`
  width: 100%;

  .upload-btns {
    width: 100%;
    ${hFlex}
    justify-content: flex-end;
    margin-bottom: 1em;

    button {
      margin: 0 0.5em;
    }
  }
`;

export const LiveClassSettingsEl = styled.div`
  .end-class {
    margin-top: 1em;
    ${hFlex}
    justify-content: flex-start;
    button {
      margin-left: 2em;
    }
  }

  hr {
    margin: 2em 0;
  }
`;

export const PostRollEl = styled.div`
  width: 100%;
  ${vFlex}
  justify-content: flex-start;

  .post-roll-class {
    ${hFlex}
    justify-content: flex-start;
    width: 100%;
    padding: 0.5em;
    border: 1px solid black;
    border-radius: 10px;
    margin: 0.5em 0;

    > p {
      width: 100%;
    }
  }
`;
export const CorrespondingCourseEl = styled.div`
  width: 100%;
  ${vFlex}
  justify-content: flex-start;

  .post-roll-class {
    ${hFlex}
    justify-content: flex-start;
    width: 100%;
    padding: 0.5em;
    border: 1px solid black;
    border-radius: 10px;
    margin: 0.5em 0;

    > p {
      width: 100%;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { PaymentInfoEl } from "../elements";
import CourseStore from "../store/courseStore";
import Loading from "../../../../../components/loading";
import Search from "../../../../../components/search";
import { createFilter } from "react-search-input/lib/util";
import {
	getPaymentHeaders,
	getPaymentSelectorOptions,
	KEYS_TO_FILTER_PAYMENT,
} from "../config";
import Input from "../../../../../components/input";
import Table from "../../../../../components/table";
import { paymentCellModifier } from "../helpers";
import { Text } from "../../../../../styles/typography";
import { includes, pluck } from "ramda";
import Button from "../../../../../components/button";

function PaymentInfo() {
	const [query, setQuery] = useState("");
	const [billType, setBillType] = useState("all");

	const {
		getPaymentInfo,
		paymentInfo,
		setPaymentInfo,
		curRequest: { type: courseType },
		downloadPaymentInfo,
	} = CourseStore.useContainer();

	useEffect(() => {
		(async () => {
			await getPaymentInfo();
		})();
		return () => setPaymentInfo(null);
	}, [setPaymentInfo, getPaymentInfo]);

	const bills =
		paymentInfo?.bills
			?.filter(({ status }) => billType === "all" || status === billType)
			.filter(createFilter(query, KEYS_TO_FILTER_PAYMENT)) || [];
	const regs =
		paymentInfo?.registrations
			?.filter(({ status }) => billType === "all" || status === billType)
			.filter(({ userId }) => !includes(userId, pluck("userId", bills)))
			.filter(createFilter(query, KEYS_TO_FILTER_PAYMENT)) || [];

	const earnings = paymentInfo?.earnings || {};

	const selectorOptions = getPaymentSelectorOptions(
		paymentInfo?.bills?.length,
		paymentInfo?.registrations?.length
	);

	const paidCount = bills.filter(({ status }) => status === "PAID").length;
	const unPaidCount = bills.length - paidCount;

	return (
		<PaymentInfoEl>
			<Loading loading={!paymentInfo}>
				<div style={{ width: "100%" }}>
					<Button onClick={async () => await downloadPaymentInfo()}>
						Download CSV
					</Button>
				</div>
				<Search placeholder={"Search"} onChange={setQuery} />

				<div className="basic-info">Paid: {paidCount}</div>
				<div className="basic-info">Not Paid: {unPaidCount}</div>
				<div className="basic-info">
					<Text>
						<b>Total Earnings:</b>{" "}
						{Object.entries(earnings)
							.map(([curr, amount]) => `${curr} ${amount}`)
							.join(", ")}
					</Text>
					{courseType !== "recorded" && (
						<div className="selector">
							<Input
								type="list"
								label=""
								items={selectorOptions}
								value={billType}
								onChange={(e) => setBillType(e.target.value)}
							/>
						</div>
					)}
				</div>
				<Table
					data={bills.concat(regs)}
					headers={getPaymentHeaders(bills.length, regs.length)}
					rowCellModifier={paymentCellModifier}
					stickyHeader
				/>
			</Loading>
		</PaymentInfoEl>
	);
}

export default PaymentInfo;

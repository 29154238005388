import React, { useState } from "react";
import { createFilter } from "react-search-input/lib/util";
// import Button from "../../../../../../components/button";
import Loading from "../../../../../components/loading";
import Search from "../../../../../components/search";
import Table from "../../../../../components/table";
import { Text } from "../../../../../styles/typography";
import { withStore } from "../../../../../utils/withStore";
import { recordingCellModifier } from "../helpers";
import RecordingStore from "../stores/recordingStore";
import { RecordingsEl } from "../elements/recordings";

const recordingTableHeaders = [
  { id: "id", label: "ID", width: 80 },
  { id: "name", label: "User" },
  { id: "userId", label: "User-ID", width: 80 },
  { id: "email", label: "Email" },
  { id: "classLink", label: "Class" },
  { id: "updatedAt", label: "Purchase Date" },
  { id: "price", label: "Amount" },
  { id: "paymentGateway", label: "Gateway" },
  { id: "status", label: "Status" },
];

const KEYS_TO_FILTER = ["id", "userId", "classLink", "name", "status", "price"];

function Recordings() {
  const [query, setQuery] = useState("");

  const {
    loading,
    loadFailed,
    recordingData: { earnings = {}, bills = [] } = {},
  } = RecordingStore.useContainer();

  const paidCount = bills.filter(({ status }) => status === "PAID").length;
  const unPaidCount = bills.length - paidCount;

  const recordingBills = bills.filter(createFilter(query, KEYS_TO_FILTER));

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <RecordingsEl>
        <Search placeholder="Search" onChange={setQuery} />
        <Text className="earnings-info">
          <b>Total Earnings:</b>{" "}
          {Object.entries(earnings)
            .map(([curr, amount]) => `${curr} ${amount}`)
            .join(", ")}
        </Text>
        <Text className="earnings-info">
          <b>Paid:</b> {paidCount}
          <b className="not-paid">Not Paid:</b> {unPaidCount}
        </Text>
        <div className="info">
          {/* <Button onClick={() => {}}>Download</Button> */}
          <Table
            data={recordingBills}
            headers={recordingTableHeaders}
            rowCellModifier={recordingCellModifier}
            stickyHeader
            defOrder="desc"
            defOrderBy="updatedAt"
          />
        </div>
      </RecordingsEl>
    </Loading>
  );
}

export default withStore([RecordingStore])(Recordings);

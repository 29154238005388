import React from "react";
import ClickToCopy from "../../../../../modules/clickToCopy";
import date from "date-and-time";

export const recordingCellModifier = (data, header) => {
  return header === "classLink" ? (
    <a
      href={`${process.env.REACT_APP_BASE_URL}/${data["classLink"]}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {data[header]}
    </a>
  ) : header === "updatedAt" ? (
    <ClickToCopy data={String(data[header])}>
      {date.format(new Date(data[header]), "DD MMM YYYY hh:mmA")}
    </ClickToCopy>
  ) : (
    <ClickToCopy data={String(data[header])}>{data[header]}</ClickToCopy>
  );
};

import React, { useState } from "react";
import { createFilter } from "react-search-input";
import {
	PaymentBasicInfoEl,
	PaymentInfoModalEl,
} from "../broadcastClass/elements";
import { classPaymentData } from "../../../../helpers/tableData";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import Table from "../../../../components/table";
import Search from "../../../../components/search";
import { curry, merge } from "ramda";

const headers = [
	{ id: "id", label: "ID", width: 80 },
	{ id: "user", label: "User" },
	{ id: "userId", label: "User-ID", width: 80 },
	{ id: "email", label: "Email" },
	{ id: "createdAt", label: "Created At" },
	{ id: "updatedAt", label: "Updated At" },
	{ id: "amount", label: "Amount" },
	{ id: "gateway", label: "Gateway" },
	{ id: "status", label: "Status" },
	{ id: "action", label: "Actions", sortable: false },
];

function PaymentModal({
	info,
	getPaymentInfo,
	downloadPaymentInfo,
	markBillPaid,
	highlightUserMessages,
	deregister,
}) {
	const KEYS_TO_FILTER = ["id", "user", "email", "gateway", "status", "userId"];
	const [query, setQuery] = useState("");
	const bills = info.bills.map((bill) =>
		merge(bill, {
			action: {
				status: bill.status,
				id: bill.id,
				registrationId: bill?.registrationId,
				highlightMessages: bill?.highlightMessages,
				isSeriesBill: bill?.isSeriesBill,
			},
		})
	);
	let paymentInfo = bills.filter(createFilter(query, KEYS_TO_FILTER));
	const cellModifier = curry(classPaymentData);

	return (
		<Modal
			onOpen={async () => await getPaymentInfo()}
			title="Payment Info"
			content={() => (
				<PaymentInfoModalEl>
					<Button onClick={async () => await downloadPaymentInfo()}>
						Download CSV
					</Button>
					<PaymentBasicInfoEl>
						<div>
							Total Earnings:{" "}
							{info.earnings.map((val, i) => `${i !== 0 ? "," : ""} ${val}`)}
						</div>
						<div>Paid: {info.paid}</div>
						<div>Not Paid: {info.not_paid}</div>
					</PaymentBasicInfoEl>
					<Search placeholder={"Search Bills"} onChange={setQuery} />
					<div>All Bills</div>
					<Table
						data={paymentInfo}
						headers={headers}
						rowCellModifier={cellModifier(
							markBillPaid,
							highlightUserMessages,
							deregister
						)}
						stickyHeader
					/>
				</PaymentInfoModalEl>
			)}
		/>
	);
}

export default PaymentModal;

import styled from "styled-components";
import { hFlex, vFlex } from "../../../styles/style-helpers";

export const InlineFormEl = styled.form`
  width: 100%;

  & > button {
    width: 10em;
    padding: 0.35em 0;
    margin: 1em 0;
  }
`;

export const CountryDiscountFormEl = styled.form`
  width: 100%;
  ${hFlex};
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div {
    width: 40%;
    margin: 0 5% 0 5%;
  }

  & > button {
    width: 50%;
    margin: 1em 0 0 1em;
  }
`;

export const ToggleFormEl = styled.div``;

export const EditDataEl = styled.div`
  ${hFlex};
  justify-content: flex-start;

  svg {
    margin-left: 0.5em;
    cursor: pointer;
  }

  p {
    overflow-wrap: anywhere;
  }
`;

export const ListFormEl = styled.div`
  ${hFlex};
  width: 100%;
  margin: 1em 0;

  > p {
    min-width: 25%;
  }

  > div {
    width: 100%;
  }
`;

export const FollowersEmailEl = styled.form`
  ${vFlex};
  align-items: baseline;

  > div {
    margin-bottom: 1em;
    width: 100%;
  }

  .error {
    color: red;
    font-size: 0.8em;
    margin-top: -1em;
  }

  .bodyBox2 {
    border: 1px solid black;
    padding: 0.5em;
  }

  .checkboxes {
    ${vFlex};
    width: 100%;
    align-items: baseline;

    .checkbox-grp {
      ${hFlex};
      width: 100%;
      justify-content: space-between;

      > div {
        width: 50%;
      }
    }
  }
`;

export const ListSubmitFormEl = styled.div`
  width: 100%;
  margin: 1em 0;

  > div {
    width: 100%;
    ${hFlex};
    justify-content: space-evenly;

    > div {
      min-width: 70%;
    }
  }
`;

import styled from "styled-components";
import Search from "../../../../../components/search";
import { vFlex } from "../../../../../styles/style-helpers";

export const RecordingsEl = styled.div`
  width: 100%;
  ${vFlex}
  overflow: auto;
  justify-content: flex-start;
  padding: 1em 2em;

  ${Search} {
    width: 100%;
    padding: 0.5em;
  }

  .earnings-info {
    width: 100%;
    margin: 0.25em 0;

    .not-paid {
      margin-left: 2.5em;
    }
  }

  .info {
    width: 100%;
    overflow: auto;

    .MuiTableContainer-root {
      height: 100%;
    }

    a {
      color: red;
      text-decoration: underline;
    }
  }
`;

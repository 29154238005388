import React, { useState } from "react";
import { withStore } from "../../../../../../utils/withStore";
import SubscriptionStore from "../../stores/subscriptionStore";
import SubscriptionTable from "./components/subscriptionTable";
import Search from "../../../../../../components/search";
import { createFilter } from "react-search-input/lib/util";
import { SubscriptionsEl } from "../../elements/subscriptions";
import Loading from "../../../../../../components/loading";

const KEYS_TO_FILTER = ["name", "email", "plan", "purchaseDate", "endDate"];

function Subscriptions() {
  const {
    subscriptions = [],
    loading,
    loadFailed,
  } = SubscriptionStore.useContainer();
  const [query, setQuery] = useState("");

  subscriptions.sort((a, b) => b.bills.length - a.bills.length);

  let filteredSubscriptions = subscriptions.filter(
    createFilter(query, KEYS_TO_FILTER)
  );

  return (
    <Loading loading={loading} loadFailed={loadFailed}>
      <SubscriptionsEl>
        <Search placeholder={"Search"} onChange={setQuery} />
        <SubscriptionTable subscriptions={filteredSubscriptions} />
      </SubscriptionsEl>
    </Loading>
  );
}

export default withStore([SubscriptionStore])(Subscriptions);

import { useCallback, useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import useSWR from "swr";
import {
	courseDataParser,
	dataToCourseTab,
	getFilteredData,
} from "../../../../../helpers/dataParsers";
import { useParams } from "../../../../../utils/hooks/useParams";
import { navigate } from "@reach/router";
import { CourseApi } from "../../../../../api/course";
import { Notification } from "../../../../../modules/toastNotification";
import CoursePaymentInfo from "../../../../../schemas/coursePaymentInfo";

const KEYS_TO_FILTER = ["status"];

const CourseStore = createContainer(() => {
	const API = CourseApi;
	const params = useParams("/dashboard/courses/:filter/:requestId");
	const [curRequest, setcurRequest] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(null);

	const {
		data: allRequests,
		mutate,
		error,
	} = useSWR(["admin/courses", "protected", courseDataParser]);

	useEffect(() => {
		if (params?.requestId !== undefined && allRequests) {
			const req = allRequests.find(({ id }) => id === params.requestId);
			console.log("req", req, allRequests);
			setcurRequest(req);
		}
	}, [allRequests, setcurRequest, params]);

	const filteredRequests = useMemo(() => {
		if (allRequests) {
			const filteredData = getFilteredData(
				`course_${params.filter}`,
				allRequests,
				KEYS_TO_FILTER
			);
			return dataToCourseTab(filteredData);
		} else return null;
	}, [allRequests, params.filter]);

	const updateRequestStatus = useCallback(
		async (status, msg) => {
			const { success } =
				curRequest.status === "PENDING_EDITS"
					? await API?.updateEditRequest(
							params.requestId,
							status === "APPROVED",
							msg
					  )
					: await API?.updateRequestStatus(params.requestId, status, msg);
			if (success) {
				await mutate();
				setcurRequest(null);
				navigate("../", { replace: true });
			}
		},
		[curRequest, setcurRequest, API, params, mutate]
	);

	const toggleMaster = useCallback(
		async (status) => {
			const { success } = await API?.masterToggleRequest(
				params.requestId,
				status
			);
			if (success) {
				await mutate();
			}
		},
		[mutate, params, API]
	);
	const toggleCertification = useCallback(
		async (val) => {
			const { success } = await API?.courseEditRequest(
				params.requestId,
				curRequest.teacherId,
				{ hasCertification: val }
			);
			if (success) {
				await mutate();
			}
		},
		[params, API, curRequest, mutate]
	);

	const togglePrivate = useCallback(
		async (status) => {
			const { success } = await API?.privateToggleRequest(
				params.requestId,
				status
			);
			if (success) {
				await mutate();
			}
		},
		[mutate, API, params]
	);

	const courseEditRequest = useCallback(
		async (updates) => {
			const { success } = await API?.courseEditRequest(
				params.requestId,
				curRequest.teacherId,
				updates
			);
			if (success) {
				await mutate();
			}
		},
		[params, API, curRequest, mutate]
	);

	const deleteRequest = useCallback(async () => {
		const { success } = await API?.deleteRequest(params.requestId);
		if (success) {
			await mutate();
			setcurRequest(null);
			navigate("../", { replace: true });
		}
	}, [API, mutate, params, setcurRequest]);

	const updateCoverImage = useCallback(
		async (image) => {
			const { success } = await API?.setCoverImage(params.requestId, image.id);
			if (success) {
				await mutate();
			}
		},
		[API, params, mutate]
	);

	const setCourseCover = useCallback(
		async (teacherId, image) => {
			const { success } = await API?.setCoverImage(
				params.requestId,
				teacherId,
				image.id
			);

			if (success) {
				Notification("success", "Course Cover updated.");
				await mutate();
			}
		},
		[API, params, mutate]
	);

	const addDiscount = useCallback(
		async (coupon) => {
			const newval = { ...curRequest.countryDiscounts, ...coupon };
			const { success } = await API?.setCourseDiscount(curRequest.id, newval);
			if (success) {
				await mutate();
			}
		},
		[curRequest, API, mutate]
	);

	const getPaymentInfo = useCallback(async () => {
		const { success, result } = await API?.getPaymentInfo(curRequest.id);

		if (success) {
			setPaymentInfo(CoursePaymentInfo(result));
		}
	}, [curRequest, API, setPaymentInfo]);
	const downloadPaymentInfo = useCallback(async () => {
		const { success, result } = await API?.getPaymentInfoCSV(curRequest.id);
		if (success) {
			const element = document.createElement("a");
			element.setAttribute(
				"href",
				"data:application/octet-stream," + encodeURIComponent(result)
			);

			element.setAttribute("download", `Payment Data - ${new Date()}.csv`);

			element.style.display = "none";
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		}
	}, [curRequest]);

	const rescheduleBatch = useCallback(
		async (batchId, data) => {
			const { success } = await API?.rescheduleBatch(
				batchId,
				data,
				"rescheduled"
			);

			if (success) {
				await mutate();
			}
		},
		[mutate, API]
	);

	const batchEditRequest = useCallback(
		async (id, data) => {
			const { success } = await API?.batchEditRequest(id, data);

			if (success) {
				await mutate();
			}
		},
		[API, mutate]
	);

	const renewBatchRequest = useCallback(
		async (id) => {
			const { success } = await API?.batchRenew(id);

			if (success) {
				await mutate();
			}
		},
		[API, mutate]
	);

	const setCoureCoverVideo = useCallback(
		async (val) => {
			const { success } =
				val === ""
					? await API?.deleteCoverVideo(curRequest.id)
					: await API?.addCoverVideo(curRequest.id, {
							fileName: "Cover video",
							location: val,
							fileType: "url",
							fileSize: 0,
					  });
			if (success) await mutate();
		},
		[curRequest, API, mutate]
	);

	return {
		loading: !filteredRequests,
		loadFailed: Boolean(error),
		requestLoading: !curRequest,
		filteredRequests,
		curRequest,
		params,
		toggleMaster,
		toggleCertification,
		togglePrivate,
		updateRequestStatus,
		deleteRequest,
		courseEditRequest,
		updateCoverImage,
		setCourseCover,
		addDiscount,
		getPaymentInfo,
		paymentInfo,
		setPaymentInfo,
		rescheduleBatch,
		batchEditRequest,
		setCoureCoverVideo,
		allCourses: allRequests,
		renewBatchRequest,
		downloadPaymentInfo,
	};
});

export default CourseStore;
